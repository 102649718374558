import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from './support.module.css'

const Support = () => {
  const handleClick = (event) => {
    if (window) {
      window.open('mailto:pillpunctual@protonmail.com')
    }
    event.preventDefault()
  }
  return (
    <Layout>
      <SEO title="Support" />
      <div className={styles.linkContainer}>
        When you are experiencing technical issues please contact us via <a href="/" className={styles.link} onClick={handleClick}>email</a>.
      </div>
    </Layout>
  )
  }

export default Support
